export const ACCESSORIES_CATEGORIES = {
  sunglass: [
    "aviator",
    "wayfarer",
    "round",
    "cat eye",
    "butterfly",
    "full rim",
    "rimless",
    "half rim",
  ],
  shoes: [
    "sneaker",
    "boot",
    "loafer",
    "oxford",
    "derby",
    "mule",
    "wedge",
    "moccasins",
    "brogues",
  ],
  sandal: [
    "sandal",
    "jutti",
    "slider",
    "flats",
    "kolhapuri",
    "chappal",
    "slip-on",
  ],
  bag: [
    "bucket",
    "baguette",
    "sling",
    "tote",
    "hobo",
    "moon",
    "satchel",
    "mini",
    "box",
    "bagpack",
    "laptop",
  ],
  belt: [],
  button: [],
  cufflink: ["classic", "novelty", "engraved", "vintage", "sports", "wedding"],
  "pocket-square": [
    "graphic",
    "plain",
    "presidential",
    "tv fold",
    "straight",
    "puff",
    "reverse puff",
    "winged puff",
    "scallop",
    "cagney",
    "dunaway",
    "rose",
    "crown",
    "diagonal",
    "paisley",
    "rolled border",
    "triangle",
    "flute",
    "accordion",
    "wave",
    "stairs",
    "astrid",
    "blooming",
  ],
};
